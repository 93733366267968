/* src/components/HomePage.css */

/* General styles for the homepage */
.homepage {
    padding: 2rem; /* Space around the sections */
}

/* Styles for each section */
.overview, .services, .engineers, .pricing, .testimonials, .tech-stack, .faq, .founder {
    border-radius: 0.5rem; /* Rounded corners */
}

/* Carousel styles */
.carousel {
    display: flex;
    overflow-x: auto; /* Allows horizontal scrolling */
    padding: 1rem 0; /* Space above and below carousel */
}

/* Engineer card styles */
.engineer-card {
    background-color: #f0f0f0; /* Light gray background for engineer cards */
    border-radius: 0.5rem; /* Rounded corners */
    padding: 1rem; /* Space inside each card */
    text-align: center; /* Center text in cards */
}

/* Package styles */
.package {
    background-color: #f9fafb; /* Light gray background for packages */
    border-radius: 0.5rem; /* Rounded corners */
    padding: 1rem; /* Space inside package */
    text-align: center; /* Center text in packages */
}

/* Tech stack item styles */
.tech-item {
    background-color: #f0f0f0; /* Light gray background for tech items */
    border-radius: 0.5rem; /* Rounded corners */
    padding: 1rem; /* Space inside each tech item */
    text-align: center; /* Center text in tech items */
}
