/* src/components/Header.css */

/* General styles for the header */
header {
    background-color: white; /* Header background */
    transition: background-color 0.3s; /* Smooth background transition */
}

/* Logo styles */
.logo h1 {
    margin: 0; /* Remove default margin */
}

/* Dropdown menu styles */
.relative:hover .absolute {
    display: block; /* Show dropdown on hover */
}

.absolute {
    display: none; /* Hide dropdown by default */
    z-index: 20; /* Ensure dropdown is above other content */
}

/* Dropdown item hover effect */
.absolute a:hover {
    background-color: #f3f4f6; /* Light gray on hover */
}

/* Hamburger menu styles */
.md\:hidden {
    display: none; /* Hide hamburger menu on medium and larger screens */
}

/* Responsive styles */
@media (max-width: 768px) {
    .md\:hidden {
        display: flex; /* Show hamburger on small screens */
    }
    nav {
        display: none; /* Hide nav on mobile by default */
    }
    .flex-col {
        flex-direction: column; /* Stack items on small screens */
    }
}
