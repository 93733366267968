/* src/components/Footer.css */

.footer {
    background-color: #1E40AF; /* Darker blue for consistency */
    color: white;
    padding: 2rem; /* Increased padding for a spacious feel */
    text-align: center; /* Center the text */
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
}

.footer .copyright {
    margin-bottom: 1rem; /* Space between sections */
    font-size: 0.9rem; /* Slightly smaller text for copyright */
}

.footer .social {
    display: flex;
    gap: 1.5rem; /* Space between social links */
}

.footer .social p {
    cursor: pointer; /* Indicates clickable items */
    transition: color 0.3s; /* Smooth transition for hover */
}

.footer .social p:hover {
    color: #FBBF24; /* Change color on hover (yellow) */
}
